import {makeStateKey, TransferState} from '@angular/platform-browser'
import {HttpClient} from '@angular/common/http'
import {TranslateLoader} from '@ngx-translate/core'
import {TranslateHttpLoader} from '@ngx-translate/http-loader'
import {Observable} from 'rxjs'

import {TSK_I18N} from '@chatize-ui/constants/transfer-state-keys'

class TranslateBrowserLoader implements TranslateLoader {

  constructor(
    private http: HttpClient,
    private transferState: TransferState,
  ) {
  }

  public getTranslation(lang: string): Observable<any> {
    const translation = this.transferState.get(makeStateKey<any>(TSK_I18N), null)

    if (translation) {
      return new Observable((observer) => {
        observer.next(translation)
        observer.complete()
      })
    } else {
      return new TranslateHttpLoader(this.http).getTranslation(lang)
    }
  }
}

export function translateLoaderFactoryBrowser(httpClient: HttpClient, transferState: TransferState) {
  return new TranslateBrowserLoader(httpClient, transferState)
}
