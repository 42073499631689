import {Routes, UrlSegment} from '@angular/router'

import {DEFAULT_LANG} from '@chatize-ui/constants/default-lang'
import {POS_PARAM_LANG} from '@chatize-ui/constants/pos-params'

import {appEnv} from '@main-ui/env'
import {AuthGuard} from '@main-ui/core/guards/auth-guard.service'
import {APP_ROUTES} from '@main-ui/app/app-routes'

const isLang = (lang: string): boolean => appEnv.languages.includes(lang)

const getMatcher = (destination: string) => (segments: UrlSegment[]) => {
  let language = DEFAULT_LANG

  if (segments[0] && isLang(segments[0].path)) {
    language = segments[0].path
  }

  const url = segments.map(s => s.path).filter(path => !isLang(path)).join('/')

  const posParams: any = {
    [POS_PARAM_LANG]: new UrlSegment(language, {})
  }

  // console.log({url, destination})

  if (url === destination) {
    return {
      consumed: segments,
      posParams,
    }
  }

  return null
}

export const getAppRoutes = (): Routes => {
  return APP_ROUTES.map(route => {
    const newRoute = {...route}

    newRoute.matcher = getMatcher(route.path)
    delete newRoute.path

    if (route.path === 'dashboard') {
      newRoute.canActivate = [AuthGuard]
    }

    return newRoute
  })
}
