import {globalEnv} from '@chatize-ui/environments/environment.qa'
import {AppCode} from '@chatize-ui/constants/app-code'
import {Environment} from '@chatize-ui/schemes/environment'

export const appEnv: Environment = {
  ...globalEnv,
  appCode: AppCode.Main,
  keycloakConfig: {
    url: 'https://id.containerize.app',
    realm: 'chatize-com',
    clientId: 'chatize-com-qa',
  },
}
