import {KeycloakService} from "keycloak-angular";

import {Config} from '@chatize-ui/services/config'

export const initKeycloak = (keycloak: KeycloakService, config: Config): any => {
  return async () => {
    if (typeof window === 'undefined') return

    await keycloak.init({
      config: config.keycloakConfig,
      initOptions: {
        checkLoginIframe: false,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      },
      bearerExcludedUrls: [
        '/assets'
      ],
    })
  }
}
