import {Routes} from '@angular/router'

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/home/home.module').then(r => r.HomeModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./features/dashboard/dashboard.module').then(r => r.DashboardModule),
    data: {ignoreInSitemap: true, preventIndexing: true},
  },
  {
    path: 'pricing',
    loadComponent: () => import('./features/pricing/pricing.component').then(r => r.PricingComponent),
  },
]
