<div class="alerts">

  @for (alert of alertService.alerts; track alert.id) {

    <div class="item item-{{alert.type}}" [class.hiding]="alert.hiding">

      <div class="message" [translate]="alert.message"></div>

      <div class="close-btn" (click)="alertService.hide(alert.id)">
        <i class="hi hi-x"></i>
      </div>

      <div class="p-bar" [ngStyle]="{ 'animation-duration' :  alert.time + 'ms'}"></div>

    </div>

  }

</div>
