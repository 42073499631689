import {APP_INITIALIZER, importProvidersFrom} from '@angular/core'
import {provideClientHydration, withNoHttpTransferCache} from '@angular/platform-browser'
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http'
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular'

import {Environment} from '@chatize-ui/schemes/environment'
import {Config} from '@chatize-ui/services/config'
import {initKeycloak} from '@chatize-ui/utils/init-keycloak'

export function initAppProvidersBrowser(environment: Environment) {
  const config = new Config(environment)

  return [
    provideClientHydration(
      withNoHttpTransferCache(),
    ),
    provideHttpClient(
      withInterceptorsFromDi(),
    ),
    {
      provide: Config,
      useValue: config,
    },
    importProvidersFrom(KeycloakAngularModule),
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService, Config],
    },
  ]
}
