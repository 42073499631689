import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'

import {initAppModuleImportsBrowser} from '@chatize-ui/utils/init-app-module-imports.browser'
import {initAppProvidersBrowser} from '@chatize-ui/utils/init-app-providers.browser'

import {appEnv} from '@main-ui/env'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ...initAppModuleImportsBrowser(),
  ],
  providers: [
    ...initAppProvidersBrowser(appEnv),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
