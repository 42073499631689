import {NgModule} from '@angular/core'
import {RouterModule} from '@angular/router'

import {getAppRoutes} from '@main-ui/core/utils/get-app-routes'

@NgModule({
  imports: [
    RouterModule.forRoot(getAppRoutes()),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {
}
