import {Environment} from '@chatize-ui/schemes/environment'
import {AppMode} from '@chatize-ui/constants/app-mode'

export const globalEnv: Environment = {
  appMode: AppMode.Qa,
  apiUrl: 'https://hostizeapi.chatize.com',
  listenerInterval: 10000,
  languages: [
    'en',
    'es',
    'de',
    'fr',
    'it',
    'pt',
    'nl',
    'cs',
    'et',
    'hu',
    'fi',
    'sk',
    'sv',
    // 'da',
    'pl',
    'ro',
    'ru',
    'uk',
    'id',
    'tr',
    'uz',
    'ha',
    'af',
    'vi',
    'th',
    'zh-hans',
    'ja',
    'ko',
    'hi',
    'bn',
    'ar',
    'fa',
    'ur',
  ],
}
