import {Component} from '@angular/core';
import {NgStyle} from '@angular/common'
import {TranslateModule} from '@ngx-translate/core'

import {AlertService} from '@chatize-ui/services/alert.service';

@Component({
  standalone: true,
  selector: 'chatize-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  imports: [NgStyle, TranslateModule]
})
export class AlertsComponent {

  constructor(
    public alertService: AlertService
  ) {
  }
}
