import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router'
import {KeycloakService} from 'keycloak-angular'
import {Observable} from 'rxjs'

import {PlatformService} from '@chatize-ui/services/platform.service'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(
    private keycloak: KeycloakService,
    private platformService: PlatformService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.platformService.isServer) return true

    if (this.keycloak.isLoggedIn()) return true

    this.keycloak.login({redirectUri: window.location.origin + state.url})

    return false
  }
}
