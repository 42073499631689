import {TransferState} from '@angular/platform-browser'
import {HttpClient} from '@angular/common/http'
import {TranslateLoader, TranslateModule} from '@ngx-translate/core'

import {translateLoaderFactoryBrowser} from '@chatize-ui/utils/translate-loader-factory.browser'
import {AlertsComponent} from '@chatize-ui/components/alerts/alerts.component'

export function initAppModuleImportsBrowser() {
  return [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactoryBrowser,
        deps: [HttpClient, TransferState],
      },
    }),
    AlertsComponent,
  ]
}
